.page-content {
  font-size: 14px;
}
.page-content h1,
.page-content h2,
.page-content h3 {
  font-weight: 600;
}
.page-content h4 {
  font-weight: 700;
}
.page-content h1 {
  font-size: 2.85714em;
  line-height: 1;
  margin-bottom: 12px;
}
.page-content h1 .icon-star {
  font-size: 0.75em;
}
.page-content .tabs-container > h2 {
  margin-top: 1em;
}
.page-content h1 + p {
  padding-top: 6px;
}
.page-content h2 {
  font-size: 1.71429em;
  line-height: 1.2;
  margin-bottom: 12px;
}
.page-content h3 {
  font-size: 1.28571em;
  line-height: 1.2;
  margin-bottom: 12px;
}
.page-content h3.greyheader {
  color: #bab9b9;
  margin-bottom: 0;
}
.page-content h4 {
  font-size: 0.85714em;
  line-height: 1.2;
  margin-bottom: 6px;
  text-transform: uppercase;
}
.page-content h4.greyheader {
  color: #bab9b9;
  margin-bottom: 0;
}
.page-content h4 span {
  color: #888;
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-weight: 400;
}
.page-content h5 {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 1em;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 6px;
}
.page-content p {
  line-height: 150%;
  margin-bottom: 0.85714em;
}
.page-content p.lead {
  font-size: 1.42857em;
}
.page-content p.center {
  text-align: center;
}
.page-content p.nomargin {
  margin-bottom: 0;
}
.page-content p + h5 {
  padding-top: 12px;
}
.page-content p + h4,
.page-content p + h3,
.page-content table + h4,
.page-content ul + h4,
.page-content ol + h4 {
  padding-top: 6px;
}
.page-content ul,
.page-content ol {
  line-height: 150%;
  margin: 0 0 0.85714em 24px;
  padding: 0;
}
.page-content ul ul,
.page-content ul ol,
.page-content ol ul,
.page-content ol ol {
  margin-bottom: 0;
}
.page-content ul.clean-list,
.page-content ol.clean-list {
  list-style: none;
  margin-left: 0;
}
.page-content ul + section.hr-top,
.page-content ol + section.hr-top {
  padding-top: 12px;
}
.page-content input[type="text"],
.page-content input[type="email"],
.page-content input[type="password"],
.page-content input[type="tel"],
.page-content input[type="number"],
.page-content input[type="search"],
.page-content input[type="date"],
.page-content textarea,
.page-content select {
  -moz-backface-visibility: hidden;
  -moz-border-radius: 5px;
  -moz-transition: all 0.1s ease-in-out;
  -ms-backface-visibility: hidden;
  -ms-border-radius: 5px;
  -o-backface-visibility: hidden;
  -o-border-radius: 5px;
  -o-transition: all 0.1s ease-in-out;
  -webkit-appearance: none;
  -webkit-backface-visibility: hidden;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.1s ease-in-out;
  backface-visibility: hidden;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #333;
  font-size: 1em;
  padding: 8px 10px;
  transition: all 0.1s ease-in-out;
}
.page-content input[type="text"] :hover,
.page-content input[type="email"] :hover,
.page-content input[type="password"] :hover,
.page-content input[type="tel"] :hover,
.page-content input[type="number"] :hover,
.page-content input[type="search"] :hover,
.page-content input[type="date"] :hover,
.page-content textarea :hover,
.page-content select :hover {
  border-color: #aaa;
}
.page-content input[type="text"] :focus,
.page-content input[type="email"] :focus,
.page-content input[type="password"] :focus,
.page-content input[type="tel"] :focus,
.page-content input[type="number"] :focus,
.page-content input[type="search"] :focus,
.page-content input[type="date"] :focus,
.page-content textarea :focus,
.page-content select :focus {
  -moz-box-shadow: 0 0 5px rgba(45, 104, 146, 0.25);
  -webkit-box-shadow: 0 0 5px rgba(45, 104, 146, 0.25);
  background-color: #fff;
  border: 1px solid #2d6892;
  box-shadow: 0 0 5px rgba(45, 104, 146, 0.25);
  outline: 0 none;
}
.page-content input[type="text"] .error,
.page-content input[type="email"] .error,
.page-content input[type="password"] .error,
.page-content input[type="tel"] .error,
.page-content input[type="number"] .error,
.page-content input[type="search"] .error,
.page-content input[type="date"] .error,
.page-content textarea .error,
.page-content select .error {
  border-color: #ad3124;
}
.page-content input[type="text"] .error :focus,
.page-content input[type="email"] .error :focus,
.page-content input[type="password"] .error :focus,
.page-content input[type="tel"] .error :focus,
.page-content input[type="number"] .error :focus,
.page-content input[type="search"] .error :focus,
.page-content input[type="date"] .error :focus,
.page-content textarea .error :focus,
.page-content select .error :focus {
  -moz-box-shadow: 0 0 5px rgba(173, 49, 36, 0.25);
  -webkit-box-shadow: 0 0 5px rgba(173, 49, 36, 0.25);
  box-shadow: 0 0 5px rgba(173, 49, 36, 0.25);
}
.page-content input[type="text"] .success,
.page-content input[type="email"] .success,
.page-content input[type="password"] .success,
.page-content input[type="tel"] .success,
.page-content input[type="number"] .success,
.page-content input[type="search"] .success,
.page-content input[type="date"] .success,
.page-content textarea .success,
.page-content select .success {
  border-color: #728e0c;
}
.page-content input[type="text"] :disabled,
.page-content input[type="email"] :disabled,
.page-content input[type="password"] :disabled,
.page-content input[type="tel"] :disabled,
.page-content input[type="number"] :disabled,
.page-content input[type="search"] :disabled,
.page-content input[type="date"] :disabled,
.page-content textarea :disabled,
.page-content select :disabled {
  border-color: #e7e7e7;
  color: #9f9f9f !important;
}
.page-content input[type="button"] {
  font-size: 100%;
}
.page-content select {
  background: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(0, 0, 0, 0.05))), url("../../img/arrow-down.png") no-repeat right center, white;
  background: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.05)), url("../../img/arrow-down.png") no-repeat right center, white;
  background: -moz-linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.05)), url("../../img/arrow-down.png") no-repeat right center, white;
  background: -o-linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.05)), url("../../img/arrow-down.png") no-repeat right center, white;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.05)), url("../../img/arrow-down.png") no-repeat right center, white;
  padding-right: 48px;
  padding-right: 8px;
}
.page-content h1 select,
.page-content h2 select,
.page-content h3 select,
.page-content h4 select {
  margin-left: 6px;
}
@-moz-document url-prefix() {
  .page-content select {
    background-image: none;
    padding-right: 8px;
  }
}
.page-content input:invalid,
.page-content textarea:invalid {
  background-color: #f9f9f9;
  border-color: #ad3124;
}
.page-content input[type="checkbox"]:focus,
.page-content input[type="radio"]:focus {
  outline: 1px dotted #333;
}
.page-content form input[type="text"],
.page-content form input[type="email"],
.page-content form input[type="file"],
.page-content form input[type="password"],
.page-content form input[type="tel"],
.page-content form input[type="number"],
.page-content form input[type="search"],
.page-content form input[type="date"],
.page-content form textarea,
.page-content form select {
  margin-bottom: 1.42857em;
}
.page-content form.inline-form input[type="text"],
.page-content form.inline-form input[type="email"],
.page-content form.inline-form input[type="file"],
.page-content form.inline-form input[type="password"],
.page-content form.inline-form input[type="tel"],
.page-content form.inline-form input[type="number"],
.page-content form.inline-form input[type="search"],
.page-content form.inline-form input[type="date"],
.page-content form.inline-form input[type="button"],
.page-content form.inline-form input[type="submit"]select {
  display: inline-block;
  margin: 0 12px 12px 0;
  width: auto;
}
.page-content .info-box,
.page-content .series-box {
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transform: translateZ(0);
  background-color: #fff;
  border: 1px solid #c7d5e1;
  border-radius: 5px;
  display: none;
  margin-bottom: 24px;
  overflow: hidden;
}
.page-content .info-box.show,
.page-content .series-box.show {
  display: block;
}
.page-content .info-box.grey,
.page-content .series-box.grey {
  border-color: #d9d9d9;
}
.page-content .info-box.selected,
.page-content .series-box.selected {
  border-color: #728e0c;
}
.page-content .series-box {
  display: block;
}
.page-content .info-box--title {
  background-color: #f9fcfd;
  padding: 12px;
  position: relative;
}
.page-content .grey .info-box--title {
  background-color: #f9f9f9;
}
.page-content .selected .info-box--title {
  background-color: #e9f6bc;
}
.page-content .info-box-large .info-box--title {
  padding: 18px 24px;
}
.page-content .info-box--title.toggle-trigger {
  cursor: pointer;
}
.page-content .info-box--title.toggle-trigger:after {
  content: "\e02d";
  font-family: 'icomoon';
  margin: -8px 0 0 0;
  position: absolute;
  right: 6px;
  top: 50%;
}
.page-content .info-box--title.toggle-trigger.toggle-open:after {
  content: "\e02e";
}
.page-content .info-box--title h1,
.page-content .info-box--title h2,
.page-content .info-box--title h3,
.page-content .info-box--title h4,
.page-content .info-box--title h5 {
  margin-bottom: 0;
}
.page-content .info-box--title i {
  margin-right: 6px;
  position: relative;
  top: 1px;
}
.page-content .info-box--title .info-box--close {
  cursor: pointer;
  font-size: 10px;
  height: 18px;
  margin: -9px 0 0 0;
  padding-top: 4px;
  position: absolute;
  right: 6px;
  text-align: center;
  top: 50%;
  width: 18px;
}
.page-content .info-box--body {
  border-top: 1px solid #c7d5e1;
  padding: 12px;
}
.page-content .grey .info-box--body {
  border-top: 1px solid #d9d9d9;
}
.page-content .selected .info-box--body {
  border-top: 1px solid #728e0c;
}
.page-content .info-box-large .info-box--body {
  padding: 24px;
}
.page-content .info-box--body p:last-child {
  margin-bottom: 0;
}
.page-content .info-box--body ul {
  list-style: disc outside;
}
nav .page-content ul,
nav .page-content ol {
  line-height: normal;
  margin: 0;
}
.subhero-bar {
  background-color: #e6e6e6;
  margin-left: -12px;
  margin-right: -12px;
  position: relative;
}
@media (min-width: 769px) {
  .subhero-bar {
    margin-left: -24px;
    margin-right: -24px;
  }
}
.subhero-bar.subhero-clean {
  background: none;
}
.subhero-bar + .subhero-bar {
  border-top: 0;
}
.subhero-bar .page-content {
  padding: 12px 0;
}
.subhero-bar h4,
.subhero-bar p,
.subhero-bar nav,
.subhero-bar ul,
.subhero-bar ol,
.subhero-bar li {
  display: inline-block;
  line-height: 14px;
  margin: 0;
  padding: 0;
}
.subhero-bar h2 {
  line-height: 34px;
  margin: 0;
  padding: 0;
}
.subhero-bar h4 {
  margin-right: 12px;
}
.subhero-bar .list-item {
  display: block;
  padding: 6px 12px 6px 0;
}
#hero .page-content > h1 {
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}
/* increasing icon size */
.icon-rating {
  background-repeat: no-repeat;
  overflow: hidden;
  height: 28px;
  width: 28px;
  background-size: 28px 28px;
  display: inline-block;
  position: relative;
  top: 4px;
}
/*for icon on showtime page*/
.layout-showtimes .icon-rating {
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
}
/*fix height for homepage posters*/
.featured-posters {
  height: 215px;
}
/* for ajax overlay*/
.ajax-overlay {
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  width: 100%;
  z-index: 100000;
}
.ajax-overlay img {
  position: relative;
  left: 45%;
}
/* for pagination */
.pagination--list .current {
  color: #000;
  pointer-events: none;
}
.pagination--list .paging-dots,
.pagination--list .paging-dots + li {
  border-style: none;
  border-width: 0px;
}
/*specialty theatres CCDS Stars and Strollers etc.*/
@media (min-width: 769px) {
  .mobile-nav-link {
    display: none;
  }
  #specialty-theatres #ShowtimesWidgetTheatre {
    float: left;
    margin-right: 3%;
  }
  #specialty-theatres #showtimesTheatre {
    height: 37px;
  }
  #specialty-theatres #showtimesDate {
    min-width: 40%;
  }
  #specialty-theatres .btnSubmitFindShowtimes {
    margin-left: 3%;
  }
  .gallerySlider .rsImgContainer {
    overflow: hidden;
  }
  .media .img img {
    max-width: 162px;
  }
  .slider--footer-inner .grid,
  .slider--footer-inner .grid--rev,
  .slider--footer-inner .grid--full,
  .slider--footer-inner .grid--right,
  .slider--footer-inner .grid--center {
    min-width: 100%;
  }
}
@media (max-width: 800px) {
  #specialty-theatres #showtimesDate {
    margin-top: 10px;
    min-width: 100%;
  }
  #specialty-theatres .btnSubmitFindShowtimes {
    margin-left: 32%;
  }
}
.food-drink-section .figure {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 42%;
}
@media (max-width: 768px) {
  .food-drink-section .figure {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.food-drink-toggle {
  margin-top: 5px;
}
#staticMaps .modal-content {
  text-align: center;
}
.time-based-experience-type {
  font-weight: 700;
  padding-top: 5px;
  display: block;
}
/* Showtime Card box shadow*/
.showtime-card.showtime-single,
.showtime-card.showtime-feature {
  -webkit-box-shadow: 0 0 4px #999999;
  -moz-box-shadow: 0 0 4px #999999;
  box-shadow: 0 0 4px #999999;
}
/*styles for french header*/
@media (min-width: 925px) {
  .menu-billets {
    width: 13%;
  }
  .menu-cinemas {
    width: 15.4%;
  }
  .menu-videos {
    width: 13.2%;
  }
  .menu-nouvelles {
    width: 16.7%;
  }
  .menu-evenements {
    width: 20.9%;
  }
  .menu-cartecadeaus {
    width: 20%;
  }
  .dropdown-container.dropdown--cinemas {
    width: 600px;
  }
  .dropdown--cinemas .dropdown-container--list li {
    width: 25%;
    float: left;
  }
  .dropdown-container.dropdown--nouvelles {
    width: 225px;
  }
  .dropdown-container.dropdown--videos {
    width: 225px;
  }
  .dropdown-container.dropdown--evenements {
    width: 440px;
  }
  .dropdown--evenements .dropdown-container--list li {
    width: 33%;
    float: left;
  }
  .dropdown-container.dropdown--cartecadeaus {
    width: 200px;
  }
}
.geolocateIp {
  display: none;
}
.removezindex {
  z-index: 4 !important;
}
/*group booking form*/
#group-booking-form input[type="radio"] {
  opacity: 1;
}
#group-booking-form .checkbox-field label {
  display: inline-block;
}
/* custom error info box */
.info-box.error {
  border-color: #d82d2d;
}
.error .info-box--title {
  background-color: #ff8c8c;
}
.error .info-box--body {
  border-top: 1px solid #d82d2d;
}
/*quizzes*/
.quiz-img {
  min-height: 200px;
  min-width: 150px;
}
.quiz-question {
  margin-bottom: 8px;
}
.blogExtraFigure {
  max-height: 100px;
}
.btn-secondary.btn-yellow {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
  background: #df9513;
  background: -moz-linear-gradient(top, #e7ae18 0, #d77c0e 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e7ae18), color-stop(100%, #d77c0e));
  background: -webkit-linear-gradient(top, #e7ae18 0, #d77c0e 100%);
  background: -o-linear-gradient(top, #e7ae18 0, #d77c0e 100%);
  background: -ms-linear-gradient(top, #e7ae18 0, #d77c0e 100%);
  background: linear-gradient(top bottom, #e7ae18 0, #d77c0e 100%);
  border: 1px solid #a45f0d;
  color: #FFFFFF !important;
}
.btn-secondary.btn-yellow:hover {
  background: #d18c13;
  background: -moz-linear-gradient(top, #d09e19 0, #bd6d0d 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #d09e19), color-stop(100%, #bd6d0d));
  background: -webkit-linear-gradient(top, #d09e19 0, #bd6d0d 100%);
  background: -o-linear-gradient(top, #d09e19 0, #bd6d0d 100%);
  background: -ms-linear-gradient(top, #d09e19 0, #bd6d0d 100%);
  background: linear-gradient(top bottom, #d09e19 0, #bd6d0d 100%);
  color: #FFFFFF !important;
}
.btn-secondary.btn-yellow:active {
  background: #c18214;
  background: -moz-linear-gradient(top, #c1941c 0%, #ad650e 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #c1941c), color-stop(100%, #ad650e));
  background: -webkit-linear-gradient(top, #c1941c 0%, #ad650e 100%);
  background: -o-linear-gradient(top, #c1941c 0%, #ad650e 100%);
  background: -ms-linear-gradient(top, #c1941c 0%, #ad650e 100%);
  background: linear-gradient(top bottom, #c1941c 0%, #ad650e 100%);
  color: #FFFFFF !important;
}
.btn-primary.btn-red {
  display: inline-block;
  margin: 0.5em 0;
  background: #CA1A1A;
  background: -moz-linear-gradient(top, #CA1A1A 0, #4C0909 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #CA1A1A), color-stop(100%, #4C0909));
  background: -webkit-linear-gradient(top, #CA1A1A 0, #4C0909 100%);
  background: -o-linear-gradient(top, #CA1A1A 0, #4C0909 100%);
  background: -ms-linear-gradient(top, #CA1A1A 0, #4C0909 100%);
  background: linear-gradient(top bottom, #CA1A1A 0, #4C0909 100%);
  color: #FFFFFF !important;
}
.btn-primary.btn-red:hover {
  background: #A51515;
  background: -moz-linear-gradient(top, #A51515 0, #2C0505 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #A51515), color-stop(100%, #2C0505));
  background: -webkit-linear-gradient(top, #A51515 0, #2C0505 100%);
  background: -o-linear-gradient(top, #A51515 0, #2C0505 100%);
  background: -ms-linear-gradient(top, #A51515 0, #2C0505 100%);
  background: linear-gradient(top bottom, #A51515 0, #2C0505 100%);
  color: #FFFFFF !important;
}
@media (max-width: 768px) {
  .ad-wide {
    padding: 5px 0px !important;
  }
  .ad-single {
    border: 0px !important;
    padding: 0px !important;
  }
  .home-slider {
    margin: 24px -24px 0 !important;
  }
}
@media (min-width: 769px) {
  ul#socialAppLinks li {
    margin: 0;
    padding: 0;
    list-style: none;
    float: left;
    margin-right: 3px;
    border-radius: 5px;
    height: 66px;
    -webkit-transition: all 0.3s ease-out;
    /* Chrome 1-25, Safari 3.2+ */
    -moz-transition: all 0.3s ease-out;
    /* Firefox 4-15 */
    -o-transition: all 0.3s ease-out;
    /* Opera 10.50â€“12.00 */
    transition: all 0.3s ease-out;
    /* Chrome 26, Firefox 16+, IE 10+, Opera 12.10+ */
  }
  ul#socialAppLinks li:hover {
    background: rgba(255, 255, 255, 0.69);
    cursor: pointer;
  }
  ul#socialAppLinks li:nth-child(3) {
    margin-right: 12%;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  ul#socialAppLinks {
    display: block;
    text-align: center;
  }
}
@media (max-width: 480px) {
  ul#socialAppLinks {
    display: block;
    text-align: center;
  }
  ul#socialAppLinks li:nth-child(3) {
    margin: 0 21%;
    text-align: center;
  }
}
ul#newsSocialLinks {
  margin: 0px !important;
}
ul#newsSocialLinks li {
  padding: 0;
  list-style: none;
  float: left;
  margin: 0 1.6% 10%;
  border-radius: 5px;
  height: 44px;
  -webkit-transition: all 0.3s ease-out;
  /* Chrome 1-25, Safari 3.2+ */
  -moz-transition: all 0.3s ease-out;
  /* Firefox 4-15 */
  -o-transition: all 0.3s ease-out;
  /* Opera 10.50â€“12.00 */
  transition: all 0.3s ease-out;
  /* Chrome 26, Firefox 16+, IE 10+, Opera 12.10+ */
}
ul#newsSocialLinks li:hover {
  background: rgba(0, 0, 0, 0.99);
  cursor: pointer;
}
ul#newsSocialLinks li img {
  width: 44px;
  height: 44px;
}
/*special image class for embedded polls*/
.img-embed {
  margin-bottom: 5px;
}
.homepage-release-date {
  display: inline-block;
  padding-bottom: 10px;
}
/* Generic Form Validaiton */
.field-validation-error {
  position: relative;
  clear: left;
  float: left;
  background-color: #c24d30;
  border: 1px solid #ad3124;
  color: #fff;
  font-weight: 700;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  padding: 7px 10px;
  margin: -12px 0 12px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.field-validation-error:before {
  top: -1px;
  border-bottom: 5px solid #ad3124;
}
.field-validation-error:before,
.field-validation-error:after {
  content: "";
  display: block;
  position: absolute;
  left: 12px;
  margin-top: -5px;
  height: 0;
  width: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.field-validation-error:after {
  top: 0;
  border-bottom: 5px solid #c24d30;
}
.input-validation-error {
  border-color: #ad3124 !important;
}
.valid {
  border-color: #728e0c !important;
}
.required label:first-child:after {
  content: " *";
  color: #c24d30;
}
/* Styles for adding the Olympic Ring to the top beside the logo*/
/*@media (min-width: 481px) {
    img.olympiclogo {
        position:absolute;
        top:-2px;
        left:140px;
    }
}

@media (max-width:480px) {
    img.olympiclogo {
        display:none;
    }
}

@media (min-width:769px) {
    .portal-nav {
        margin-left:60px;
    }
}

@media (min-width:481px) and (max-width: 768px) {
    .portal-nav {
        margin-left:75px;
    }
}*/
/* increase width of showtime search attributes summary */
.layout-showtimes .title-left {
  max-width: 85%;
}
/* Party Calculator  -- START -- */
.ui-datepicker-month,
.ui-datepicker-year {
  padding: 2px !important;
}
.pc-FoodChoiceConcession {
  width: 157px;
  height: auto;
  text-align: center;
}
#PopcornParty .food-drink-section .figure {
  margin-bottom: 5px;
  margin-top: 5px;
}
@media (max-width: 480px) {
  .pc-FoodConcessionSelector {
    vertical-align: middle;
    width: 120px !important;
    padding-right: 0px;
  }
}
.XScapeCalcForm label {
  margin-bottom: 0px;
}
.highlightedPackage {
  border: 1px solid #728e0c;
  background-color: #e9f6bc;
}
/* Party Calculator -- END -- */
.showtime-toggle .btn-secondary {
  max-width: 280px !important;
}
.theatreHeaderMiniDetailsDiv {
  background-color: #FFFFFF;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: #000000;
  font-size: 12px;
  font-weight: bold;
  left: 0;
  position: absolute;
  text-transform: uppercase;
  top: 15%;
  z-index: 3;
}
@media (min-width: 320px) and (max-width: 480px) {
  .theatreHeaderMiniDetailsDiv {
    padding: 0.3em 1em;
  }
}
@media (min-width: 481px) {
  .theatreHeaderMiniDetailsDiv {
    padding: 0.3em 1.5em;
  }
}
/* to hide the "specify a time" field of the show times bar */
@media (min-width: 769px) {
  #ShowtimesWidgetMovie {
    width: 33% !important;
  }
}
@media (min-width: 769px) {
  #ShowtimesWidgetDate {
    width: 33% !important;
  }
}
#ShowtimesWidgetTime {
  display: none !important;
}
/* twitter share align*/
.twitter-share-button[style] {
  vertical-align: text-bottom !important;
}
/* fb like overflow */
.like-tweet {
  overflow: hidden !important;
  height: 22px !important;
}
.width-99 {
  width: 99%;
}
.width-120-px {
  width: 120px;
}
.trailer-home-link {
  color: #df9513;
}
.trailer-home-link:hover {
  color: #bd6d0d;
}
.btn-trailer-comingsoon {
  padding-top: 10px;
  height: 37px;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  div.showtimes-page .grid__item.nine-twelfths.mediumDown--one-whole {
    width: 100% !important;
  }
  .ad-wrap {
    display: none !important;
  }
  .no-page-break-inside {
    page-break-inside: avoid;
  }
  .page-break-before-always {
    page-break-before: always;
  }
  .page-break-after-always {
    page-break-after: always;
  }
}
/** NAV SEARCH **/
/* Search Box Border */
#SearchModule .search-input {
  border: 1px solid #C0C0C0;
  padding-right: 66px;
}
@media only screen and (max-width: 925px) and (min-width: 481px) {
  .nav-primary--search .nav-primary--search-clear {
    display: none;
  }
  .menu-item > .nav-primary--search-trigger {
    cursor: default;
    margin-left: -12px;
    padding-left: 24px;
    z-index: 5;
  }
  .nav-primary--search-input {
    display: block;
    overflow: visible;
    margin: 0 12px;
    width: 350px;
  }
  .nav-primary--search-input .nav-primary--input {
    opacity: 1;
    visibility: visible;
    width: 100%;
    height: 32px;
    padding-right: 66px;
  }
  .nav-primary--search-input .icon-search {
    position: relative;
    top: 1px;
  }
}
@media (max-width: 480px) {
  .nav-primary--search {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
  .nav-primary--search .nav-primary--search-clear {
    position: relative;
    z-index: 5;
    padding: 0;
    color: #25333f;
    display: none;
    top: 16px;
    right: 6px;
    font-size: 1.14286em;
    float: right;
  }
  .nav-primary--search .nav-primary--search-clear:before {
    display: none;
  }
  .nav-primary--search .nav-primary--search-clear:hover {
    color: white;
  }
  .nav-primary--input:focus ~ .nav-primary--search-clear {
    display: block;
  }
  .nav-primary--input {
    padding-right: 90px !important;
  }
  .nav-primary--search-back {
    float: left;
    width: 0;
    overflow: hidden;
    display: none;
  }
  .nav-primary--search-back a {
    padding: 16px 16px 14px 10px;
  }
  .nav-primary--search-back i {
    font-size: 1.28571em;
    line-height: 14px;
  }
  .nav-primary--search-input {
    width: auto;
    display: block;
  }
  .nav-primary {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }
  .nav-primary--search-open .nav-primary--search-trigger {
    position: absolute;
    top: 17px;
    left: 9px;
    padding: 0;
    color: #202E3A;
    float: none;
    border: none;
    z-index: 5;
    cursor: default;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .nav-primary--search-open .nav-primary--search-trigger i {
    color: rgba(255, 255, 255, 0.2);
  }
  .nav-primary--search-open .nav-primary--search-trigger:before {
    display: none;
  }
}
.nav-primary--search .nav-primary--input:focus {
  color: #444444;
}
.nav-primary--search .nav-primary--input:focus ~ .nav-primary--search-go {
  display: block;
}
.nav-primary--search .nav-primary--input:focus ~ a i {
  color: #444444;
}
.nav-primary--search input[type="button"]:active {
  display: block !important;
}
.nav-primary--search-go {
  display: none;
  padding: 4px 12px 3px;
  position: relative;
  right: 3px;
  float: right;
  top: 11px;
  z-index: 5;
}
.menu-item > a {
  z-index: 200;
}
/** END NAV SEARCH **/
.showtime-card.showtime-single {
  overflow: visible !important;
}
/** START FORMS HORIZONTAL **/
/* line 159, /app/source/css/partials/_forms.css.scss */
form.form-horizontal .input-wrap .control-label {
  float: left;
  text-align: right;
  padding: 8px 15px 0px 15px;
}
form.form-horizontal .input-wrap input[type="text"],
form.form-horizontal .input-wrap input[type="email"],
form.form-horizontal .input-wrap input[type="file"],
form.form-horizontal .input-wrap input[type="password"],
form.form-horizontal .input-wrap input[type="tel"],
form.form-horizontal .input-wrap input[type="number"],
form.form-horizontal .input-wrap input[type="search"],
form.form-horizontal .input-wrap input[type="date"],
form.form-horizontal .input-wrap input[type="button"],
form.form-horizontal .input-wrap input[type="submit"],
form.form-horizontal .input-wrap select {
  width: 100%;
  float: left;
}
form.form-horizontal .input-wrap.full-input {
  width: 100%;
}
form.form-horizontal .input-wrap .control-group {
  float: left;
}
form .img-upload label span {
  color: #777;
  font-weight: 400;
}
@media only screen and (max-width: 480px) {
  form.form-horizontal .input-wrap .control-label {
    float: left;
    text-align: left;
    padding: 0px;
  }
}
.contest-skill-test {
  padding-bottom: 3px;
}
/** END FORMS HORIZONTAL **/
/** START REUSABLE CSS **/
.npl {
  padding-left: 0 !important;
}
.npr {
  padding-right: 0 !important;
}
.npb {
  padding-bottom: 0 !important;
}
.npt {
  padding-top: 0 !important;
}
.nml {
  margin-left: 0 !important;
}
.nmr {
  margin-right: 0 !important;
}
.nmb {
  margin-bottom: 0 !important;
}
.nmt {
  margin-top: 0 !important;
}
/** END REUSABLE CSS*/
/* ie 10-11+ css */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  select::-ms-expand {
    display: none;
  }
  select {
    padding-right: 48px;
  }
}
.box--checkbox .checkradio .checkradio--body {
  border-top: 1px solid #d9d9d9;
  padding-top: 10px;
  padding-right: 24px;
  padding-bottom: 10px;
  padding-left: 24px;
}
/* line 424, objects/_boxes.css.scss */
.box--checkbox .checkradio .faux-input--large-yellow {
  top: 24px;
  left: 24px;
}
/* line 430, objects/_boxes.css.scss */
.box--checkbox .checkradio input:checked + label.faux-label--large-yellow .checkradio--body {
  background-color: #e7a614;
}
/* line 434, objects/_boxes.css.scss */
.box--checkbox .checkradio .faux-label--large-yellow {
  position: relative;
  cursor: pointer;
}
/* line 276, partials/_forms.css.scss */
.csschecked span.faux-input--large-yellow {
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid #2c3e4c;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  z-index: 2;
}
/* line 284, partials/_forms.css.scss */
.csschecked span.faux-input--large-yellow:focus {
  outline: 1px dotted #333333;
}
/* line 289, partials/_forms.css.scss */
.csschecked input:checked + label.faux-label--large-yellow span.faux-input--large-yellow {
  background-color: #091849;
  border-color: #091849;
}
/* line 294, partials/_forms.css.scss */
.csschecked input:focus + span.faux-input--large-yellow {
  outline: 1px dotted #333333;
}
/* line 299, partials/_forms.css.scss */
.csschecked input[type="checkbox"]:checked + label.faux-label--large-yellow span.faux-input--large-yellow:before {
  display: block;
  content: "\e01b";
  font-family: 'icomoon';
  color: #fff;
  line-height: 20px;
  width: 18px;
  text-align: center;
  font-size: 0.71429em;
}
.btn-fb,
.btn-twitter {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  font-weight: 700;
  font-family: "proxima-nova", "Helvetica Neue", helvetica, arial, sans-serif;
  text-transform: uppercase;
  text-align: center;
  border-radius: 5px;
  display: inline-block;
  color: #fff;
  padding: 8px 2px 7px;
  font-size: 1em;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
}
.btn-fb {
  background: #3c5b9e;
  background: -moz-linear-gradient(top, #486db2 0%, #2c4382 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #486db2), color-stop(100%, #2c4382));
  background: -webkit-linear-gradient(top, #486db2 0%, #2c4382 100%);
  background: -o-linear-gradient(top, #486db2 0%, #2c4382 100%);
  background: -ms-linear-gradient(top, #486db2 0%, #2c4382 100%);
  background: linear-gradient(top bottom, #486db2 0%, #2c4382 100%);
  border: 1px solid #404040;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.7);
}
.btn-fb:hover,
.btn-fb:focus {
  background: #243567;
  color: #fff;
  background: -moz-linear-gradient(top, #3d5d98 0%, #304981 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #3d5d98), color-stop(100%, #304981));
  background: -webkit-linear-gradient(top, #3d5d98 0%, #304981 100%);
  background: -o-linear-gradient(top, #3d5d98 0%, #304981 100%);
  background: -ms-linear-gradient(top, #3d5d98 0%, #304981 100%);
  background: linear-gradient(top bottom, #3d5d98 0%, #304981 100%);
}
.btn-fb:active {
  background: #2c4274;
  color: #fff;
  background: -moz-linear-gradient(top, #2c4274 0%, #2c4274 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2c4274), color-stop(100%, #2c4274));
  background: -webkit-linear-gradient(top, #2c4274 0%, #2c4274 100%);
  background: -o-linear-gradient(top, #2c4274 0%, #2c4274 100%);
  background: -ms-linear-gradient(top, #2c4274 0%, #2c4274 100%);
  background: linear-gradient(top bottom, #2c4274 0%, #2c4274 100%);
}
.btn-twitter {
  background: #00aded;
  background: -moz-linear-gradient(top, #00b5ef 0%, #009fe9 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #00b5ef), color-stop(100%, #009fe9));
  background: -webkit-linear-gradient(top, #00b5ef 0%, #009fe9 100%);
  background: -o-linear-gradient(top, #00b5ef 0%, #009fe9 100%);
  background: -ms-linear-gradient(top, #00b5ef 0%, #009fe9 100%);
  background: linear-gradient(top bottom, #00b5ef 0%, #009fe9 100%);
  border: 1px solid #006992;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
}
.btn-twitter:hover,
.btn-twitter:focus {
  background: #0082be;
  color: #fff;
  background: -moz-linear-gradient(top, #00a0d5 0%, #0095cf 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #00a0d5), color-stop(100%, #0095cf));
  background: -webkit-linear-gradient(top, #00a0d5 0%, #0095cf 100%);
  background: -o-linear-gradient(top, #00a0d5 0%, #0095cf 100%);
  background: -ms-linear-gradient(top, #00a0d5 0%, #0095cf 100%);
  background: linear-gradient(top bottom, #00a0d5 0%, #0095cf 100%);
}
.btn-twitter:active {
  background: #0073a0;
  color: #fff;
  background: -moz-linear-gradient(top, #0073a0 0%, #0073a0 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #0073a0), color-stop(100%, #0073a0));
  background: -webkit-linear-gradient(top, #0073a0 0%, #0073a0 100%);
  background: -o-linear-gradient(top, #0073a0 0%, #0073a0 100%);
  background: -ms-linear-gradient(top, #0073a0 0%, #0073a0 100%);
  background: linear-gradient(top bottom, #0073a0 0%, #0073a0 100%);
}
.icon-white:before {
  color: white;
}
#ShowtimesWidget #ShowtimesWidgetDate select {
  cursor: pointer;
}
.movie-header .social-links {
  border: none;
  background-color: transparent;
  box-shadow: none;
  top: 30%;
}
